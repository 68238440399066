import { Reducer } from "@ctra/utils";

import { cleanup } from "../../utils/reducer";
import { FarmSummarySubscriptionList } from "./typings";
import types from "./types";

export const initialState: FarmSummarySubscriptionList = [];

/**
 * Farm summaries reducer
 * @param state
 * @param action
 */
const reducer: Reducer<FarmSummarySubscriptionList> = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.FETCH_SUBSCRIPTION_LIST.fulfilled: {
      return payload;
    }
    case types.UPDATE_SUBSCRIPTION.fulfilled: {
      const { farmID, isEnabled, updatedTs } = payload;
      const index = state.findIndex(({ farmID: id }) => id === farmID);

      if (index > -1) {
        return [
          ...state.slice(0, index),
          {
            farmID,
            isEnabled,
            updatedTs
          },
          ...state.slice(index + 1)
        ];
      }

      return state;
    }
  }

  return state;
};

export default cleanup<FarmSummarySubscriptionList>(initialState)(reducer);
